import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { BridgetEnums } from '@models/bridget-enums-model';
import { Currency } from '@models/currency-model';
import { Owner } from '@models/owner-model';
import { StockLocation } from '@models/stock-location-model';
import { SupplierConfiguration } from '@models/supplier-configuration/supplier-configuration-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { GenericCache, cachedGet } from './service-cached';
import { GenericArrayCache, cachedArrayGet } from './service-cached-array';

@Injectable()
export class CommonsService {
  private bridgetEnumsCache = new GenericCache<BridgetEnums>(this.http, this.converter);
  private clientsCache = new GenericArrayCache<string>(this.http, this.converter);
  private spoStatusesCache = new GenericArrayCache<string>(this.http, this.converter);
  private stockLocationsCache = new GenericArrayCache<StockLocation>(this.http, this.converter);
  private ownersCache = new GenericArrayCache<Owner>(this.http, this.converter);
  private currencyCache = new GenericArrayCache<Currency>(this.http, this.converter, Currency);

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private environmentService: EnvironmentService,
  ) {}

  getBridgetEnums(): Observable<BridgetEnums> {
    return cachedGet<BridgetEnums>(this.bridgetEnumsCache, this.environmentService.getRestEndpoint('enums'));
  }

  getClients(): Observable<string[]> {
    return cachedArrayGet<string>(this.clientsCache, this.environmentService.getRestEndpoint('clients'));
  }

  getSpoStatuses(): Observable<string[]> {
    return cachedArrayGet<string>(this.spoStatusesCache, this.environmentService.getRestEndpoint('spoStatuses'));
  }

  getSuppliersConfiguration(): Observable<SupplierConfiguration[]> {
    const path = this.environmentService.getRestEndpoint('suppliersConfigurations');
    return this.http.get(path, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        return resp.body;
      }),
      map((resp: SupplierConfiguration[]) => resp.map((it) => this.converter.fromJSONtoObj(it, SupplierConfiguration))),
    );
  }

  getStockLocations(): Observable<StockLocation[]> {
    return cachedArrayGet<StockLocation>(this.stockLocationsCache, this.environmentService.getRestEndpoint('stockLocation') + '/all');
  }

  getOwners(): Observable<Owner[]> {
    return cachedArrayGet<Owner>(this.ownersCache, this.environmentService.getRestEndpoint('owners'));
  }

  getCurrencies(): Observable<Currency[]> {
    return cachedArrayGet<Currency>(this.currencyCache, this.environmentService.getRestEndpoint('currencies'));
  }
}
