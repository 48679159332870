import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Supplier } from '@models/supplier-model';
import { EnvironmentService } from '../../../environment.service';
import { cachedArrayGet, GenericArrayCache } from '@shared/service-cached-array';

@Injectable()
export class SuppliersService {
  private suppliersCache = new GenericArrayCache<Supplier>(this.http, this.converter, Supplier);

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private environmentService: EnvironmentService,
  ) {}

  resetSupplierCache() {
    this.suppliersCache.reset();
  }

  getSuppliers(): Observable<Supplier[]> {
    return cachedArrayGet<Supplier>(this.suppliersCache, this.environmentService.getRestEndpoint('suppliers')).pipe(
      map((r: Supplier[]) => {
        r.forEach((it) => it.initDescription());
        return r;
      }),
    );
  }

  saveSupplier(supplier: Supplier): Observable<Supplier> {
    const body = this.converter.fromObjtoJSON(supplier);
    return this.http.post(this.environmentService.getRestEndpoint('suppliers'), body).pipe(
      map((resp: Supplier) => {
        this.resetSupplierCache();
        return this.converter.fromJSONtoObj(resp, Supplier);
      }),
    );
  }

  delete(id: string): Observable<any> {
    const endpoint = this.environmentService.getRestEndpoint('suppliers');
    const path = this.environmentService.dev ? endpoint : endpoint + '/' + id;
    return this.http.delete(path).pipe(map(() => this.resetSupplierCache()));
  }
}
