import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { BrandonAutocompleteComponent } from '@shared/brandon-autocomplete/brandon-autocomplete.component';
import { DeleteDialogComponent } from './container/configuration/delete-dialog/delete-dialog-component';
import { SuppliersService } from './container/configuration/suppliers/suppliers.service';
import { GenericConfirmationModalComponent } from './container/modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { GenericErrorModalComponent } from './container/modal/generic-error-modal/generic-error-modal.component';
import { RESTInterceptor } from './core/RESTInterceptor';
import { MaterialModule } from './material.module';
import { CommonsService } from './shared/commons.service';
import { MyDateAdapter } from './shared/my-date-adapter.component';
import { PipeModule } from './shared/my-pipes';

const MY_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@NgModule({
  declarations: [GenericConfirmationModalComponent, GenericErrorModalComponent, DeleteDialogComponent, BrandonAutocompleteComponent],
  exports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    OverlayModule,
    ReactiveFormsModule,
    FormsModule,
    PipeModule,
    BrandonAutocompleteComponent,
  ],
  imports: [CommonModule, MaterialModule, FormsModule, OverlayModule, RouterModule, ReactiveFormsModule, PipeModule],
  providers: [
    PipeModule,
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: RESTInterceptor, multi: true },
    SuppliersService,
    CommonsService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SharedModule {}
