import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public baseApiUrl = '';
  public ponchBaseApiUrl = '';
  public jimboBaseApiUrl = '';
  public zionBaseApiUrl = '';
  public dev = false;
  public staging = false;
  public production = false;
  public logo = 'logo.svg';

  public getRestEndpoint(name: string): string {
    if (Object.keys(ponchRestEndpoints).includes(name)) {
      return this.dev ? mockEndpoints[name] : this.ponchBaseApiUrl + ponchRestEndpoints[name];
    }
    if (Object.keys(jimboRestEndpoints).includes(name)) {
      return this.dev ? mockEndpoints[name] : this.jimboBaseApiUrl + jimboRestEndpoints[name];
    }
    if (Object.keys(zionRestEndpoints).includes(name)) {
      return this.dev ? mockEndpoints[name] : this.zionBaseApiUrl + zionRestEndpoints[name];
    }
    return this.dev ? mockEndpoints[name] : this.baseApiUrl + restEndpoints[name];
  }
}

const ponchRestEndpoints = {
  userPermissions: `api/v1/user-permissions`,
};

const jimboRestEndpoints = {
  suppliersConfigurations: 'api/v1/suppliers-configurations',
  findSuppliersConfigurations: 'api/v1/suppliers-configurations/find',
  findJimboClients: 'api/v1/suppliers-configurations/clients',
  suppliersConfigurationsFileTypes: 'api/v1/suppliers-configurations/file-types',
  suppliersConfigurationsEndpointTypes: 'api/v1/suppliers-configurations/endpoint-types',
  suppliersConfigurationsMessageTypes: 'api/v1/suppliers-configurations/message-types',
  suppliersConfigurationsEmailTemplates: 'api/v1/suppliers-configurations/email-templates',
  saveSupplierConfiguration: 'api/v1/suppliers-configurations/save',
  suppliersConfigurationsFields: 'api/v1/suppliers-configurations/fields',
  suppliersConfigurationsSenderColumnTypes: 'api/v1/suppliers-configurations/sender-column-types',
  spos: 'api/v1/spos',
  spoItems: 'api/v1/spo-items',
  clients: 'api/v1/spos/clients',
  spoStatuses: 'api/v1/spos/statuses',
  purchaseInvoice: 'api/v1/purchase-invoice',
  purchaseInvoiceItem: 'api/v1/purchase-invoice-item',
  spoa: 'api/v1/spoa',
};

const zionRestEndpoints = {
  stockLocation: `api/v1/stock-locations`,
  owners: 'api/v1/owners',
  currencies: 'api/v1/currencies',
};

const restEndpoints = {
  authenticate: `api/v1/authenticate`,
  clients: 'api/v1/clients',
  feeds: 'api/v1/feeds',
  items: 'api/v1/items',
  login: `api/v1/login`,
  logisticPartners: 'api/v1/logistic-partners',
  newOperation: 'api/v1/new-operation',
  operations: 'api/v1/operations',
  operationStatus: 'api/v1/operations/status',
  operationDataRows: 'api/v1/operation-data-rows',
  exportOperation: 'api/v1/operations/export/',
  singleFeed: 'api/v1/feeds',
  stockBalance: 'api/v1/stock-balance',
  suppliers: 'api/v1/suppliers',
  uploadFile: 'api/v1/utils/upload',
  warehouses: 'api/v1/warehouses',
  shipments: 'api/v1/shipments',
  carriersConfigurations: 'api/v1/carrier-configs',
  saveCarriersConfiguration: 'api/v1/carrier-configs/save',
  getCarriers: 'api/v1/carrier-configs/carriers',
  uploadCarriersConfigurations: 'api/v1/carrier-configs/upload',
  removeShippyProError: 'api/v1/operations/remove-shippypro-error',
  enums: 'api/v1/enums',
  stockMovement: 'api/v1/stock-movement',
  exportStockMovement: 'api/v1/stock-movement-export',
  productAgingInfo: 'api/v1/stock-movement/aging',
  productAging: 'api/v1/product-aging',
  exportProductAging: 'api/v1/product-aging-export',

  // super
  changeRowStatus: 'api/v1/super/force-data-row-status',
  moveOperationToNew: 'api/v1/super/move-to-new/',
  sendOperation: 'api/v1/super/send-operation/',
  manualPoll: 'api/v1/super/manual-operation-poll/',
  setTrackingData: 'api/v1/super/set-tracking-data/',
};

const mockEndpoints = {
  authenticate: '../assets/mock/authenticate.json',
  businessLines: '../assets/mock/business-lines.json',
  clients: '../assets/mock/clients.json',
  currentUser: '../assets/mock/current-user.json',
  feeds: '../assets/mock/feeds.json',
  items: '../assets/mock/items.json',
  login: '../assets/mock/login.json',
  logisticPartners: '../assets/mock/logistic-partners.json',
  newOperation: '../assets/mock/operations.json',
  operationDataRows: '../assets/mock/operation-data-rows.json',
  operations: '../assets/mock/operations.json',
  operationStatus: '../assets/mock/operation-status.json',
  singleFeed: '../assets/mock/single-feed.json',
  stockBalance: '../assets/mock/stock-balance.json',
  suppliers: '../assets/mock/suppliers.json',
  uploadFile: '../assets/mock/upload-file.json',
  users: '../assets/mock/users.json',
  warehouses: '../assets/mock/warehouses.json',
};
