import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteDialogComponent } from '@container/configuration/delete-dialog/delete-dialog-component';
import { CarrierConfigsFilters, CarriersConfiguration } from '@models/carriers-configuration/carriers-configuration-model';
import { Observable, Subscription } from 'rxjs';
import { CarriersConfigurationsService } from '../carriers-configurations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNilty } from '@core/utils.service';
import { Carrier } from '@models/carrier-model';
import { CommonsService } from '@shared/commons.service';
import { Supplier } from '@models/supplier-model';
import { SuppliersService } from '@container/configuration/suppliers/suppliers.service';

@Component({
  selector: 'app-carriers-configurations-list',
  templateUrl: './carriers-configurations-list.component.html',
  styleUrls: ['./carriers-configurations-list.component.css'],
})
export class CarriersConfigurationsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('carrierFile') carrierFile: ElementRef;
  currentFileUpload: File;

  subscription: Subscription;
  carriers: Carrier[];
  countries: string[];
  suppliers: Supplier[];
  filters: CarrierConfigsFilters;

  dataSource: MatTableDataSource<CarriersConfiguration>;

  displayedColumns = [
    'carrier',
    'originCountryCode',
    'destinationCountryCode',
    'destinationZipCodePrefix',
    'contract',
    'weightKgFrom',
    'weightKgTo',
    'storeCode',
    'storeGroupCode',
    'supplierCode',
    'orderType',
    'edit',
    'delete',
  ];
  resultsNumber: Observable<number>;

  constructor(
    private service: CarriersConfigurationsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private commonService: CommonsService,
    private suppliersService: SuppliersService,
  ) {}

  ngOnInit() {
    this.filters = new CarrierConfigsFilters();
    if (!isNilty(this.service.carrierConfigsFilters)) {
      this.filters = { ...this.service.carrierConfigsFilters };
    } else {
      this.service.carrierConfigsFilters = new CarrierConfigsFilters();
    }

    this.resultsNumber = this.service.resultsNumber;
    this.dataSource = new MatTableDataSource([]);

    this.paginator.pageSize = this.service.carrierConfigsFilters.pageSize;
    this.paginator.pageIndex = this.service.carrierConfigsFilters.pageIndex;

    this.loadConfigurations();
    this.loadCarriers();
    this.loadSuppliers();
    this.commonService.getBridgetEnums().subscribe((r) => (this.countries = r.countries));
  }

  loadConfigurations() {
    this.service.carrierConfigsFilters = { ...this.filters };
    this.service.getCarriersConfigurations().subscribe((resp: CarriersConfiguration[]) => (this.dataSource.data = resp));
  }

  loadCarriers() {
    this.service.getCarriers().subscribe((resp: Carrier[]) => (this.carriers = resp));
  }

  loadSuppliers() {
    this.suppliersService.getSuppliers().subscribe((resp: Supplier[]) => (this.suppliers = resp));
  }

  deleteConf(e) {
    this.service.resetCarrierCache();
    this.subscription = this.dialog
      .open(DeleteDialogComponent, {
        data: 'Deleting configuration id ' + e,
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.service.deleteConfiguration(e).subscribe(() => {
            this.loadConfigurations();
            this.subscription.unsubscribe();
          });
        }
      });
  }

  importCarrier() {
    this.carrierFile.nativeElement.click();
  }

  carrierFileChanged(event) {
    this.currentFileUpload = event.target.files[0];
    if (this.currentFileUpload.type !== 'text/csv') {
      this.snackBar.open('ERROR! Please, select an .csv file.', 'CLOSE')._dismissAfter(5000);
    } else {
      this.service.uploadCarrierConfigurations(this.currentFileUpload).subscribe(() => {
        this.snackBar.open('File uploaded. ', 'CLOSE')._dismissAfter(3000);
        this.loadConfigurations();
      });
    }
  }

  onSort() {
    this.filters.sortBy = this.sort.active;
    this.filters.sortDirection = this.sort.direction;
    this.loadConfigurations();
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageIndex = this.paginator.pageIndex;
    this.loadConfigurations();
  }

  search() {
    this.service.carrierConfigsFilters.pageIndex = 0;
    this.loadConfigurations();
  }

  reset() {
    this.filters = new CarrierConfigsFilters();
    this.service.carrierConfigsFilters = { ...this.filters };
    this.loadConfigurations();
  }
}
