import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@security/authentication.service';
import { LoaderService } from './loader/loader.service';
import { GenericErrorModalComponent } from '@container/modal/generic-error-modal/generic-error-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';

@Injectable()
export class RESTInterceptor implements HttpInterceptor {
  requestNum = 0;

  constructor(
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = this.authenticationService.getAuthorizationHeader();
    let finalReq = req;
    this.requestNum++;

    if (authHeader) {
      // Clone the request to add the new header.
      finalReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authHeader}`),
      });
    }

    const timer = setTimeout(() => {
      this.showLoader();
    }, 0.1 * 1000);

    if (this.environmentService.dev) {
      finalReq = req.clone({ method: 'GET' });
    }

    return next.handle(finalReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.requestNum--;
            if (this.requestNum === 0) {
              this.hideLoader();
            }
            clearTimeout(timer);
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authenticationService.login();
              return;
            }

            const errDialog = this.dialog.open(GenericErrorModalComponent, {
              width: '350px',
              data: err.error,
            });

            errDialog.afterClosed().subscribe(() => {
              this.hideLoader();
              this.requestNum = 0;
            });
          }
        },
      ),
    );
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
