import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { isNil, isNilty } from '@core/utils.service';
import { Field } from '@models/operations-config-model';
import { Currency } from '@models/currency-model';
import { CommonsService } from './commons.service';

@Pipe({
  name: 'myCurrency',
})
export class MyCurrencyPipe implements PipeTransform {
  private currencies: Currency[] = [];

  constructor(
    private decimalPipe: DecimalPipe,
    private currencyService: CommonsService,
  ) {
    this.currencyService.getCurrencies().subscribe((r) => (this.currencies = r));
  }

  transform(value: number, currencyCode?: string, comma = true): string {
    if (isNilty(value) || isNilty(this.currencies)) {
      return '';
    }
    const symbol = this.currencies.find((it) => it.code === currencyCode);
    return comma
      ? `${this.decimalPipe.transform(value, '1.2-2')} ${symbol ? symbol.symbol : ''}`
      : `${this.decimalPipe.transform(value, '1.2-2').toString().replace(/,/g, '')} ${symbol ? symbol.symbol : ''}`;
  }
}

@Pipe({
  name: 'myPercent',
})
export class MyPercentPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number): string {
    if (value == null) {
      return '';
    }
    return this.decimalPipe.transform(value * 100, '1.2-2') + ' %';
  }
}

@Pipe({
  name: 'myDate',
})
export class MyDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: number): string {
    return this.datePipe.transform(value, 'dd/MM/yy');
  }
}

@Pipe({
  name: 'myDateTime',
})
export class MyDateTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: number): string {
    return this.datePipe.transform(value, 'dd/MM/yy hh:mm');
  }
}

@Pipe({
  name: 'capitalizePipe',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (value === null || value === undefined) {
      return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

@Pipe({
  name: 'isNullOrUndefinedOrEmptyString',
})
export class IsNullOrUndefinedOrEmptyString implements PipeTransform {
  transform(value: string): boolean {
    return isNilty(value);
  }
}

@Pipe({
  name: 'isNullOrUndefined',
})
export class IsNullOrUndefined implements PipeTransform {
  transform(value: any): boolean {
    return isNil(value);
  }
}

@Pipe({
  name: 'booleanIconPipe',
})
export class BooleanIconPipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? 'fa fa-check' : 'fa fa-times sel-icon';
  }
}

@Pipe({
  name: 'fileNameFromUrl',
})
export class FileNameFromUrl implements PipeTransform {
  transform(value: string): string {
    if (!isNilty(value)) {
      return value.split('/').pop();
    } else {
      return ' - ';
    }
  }
}

@Pipe({
  name: 'readProperty',
})
export class ReadPropertyPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(object: any, field: Field): any {
    if (isNilty(object)) {
      return '-';
    } else {
      const properties = field.name.split('.');
      let value: any = !isNilty(object[properties[0]]) ? object[properties[0]] : object.data ? object.data[properties[0]] : null;
      properties.splice(0, 1);
      properties.forEach((it) => {
        if (!isNilty(value)) {
          value = value[it];
        }
      });
      const ret = isNilty(value) ? '-' : value;
      if (field.class === 'Date' && ret !== '-') {
        return this.datePipe.transform(ret, 'dd/MM/yy hh:mm');
      } else {
        return ret;
      }
    }
  }
}

@Pipe({
  name: 'replaceUnderscoreWithSpace',
})
export class replaceUnderscoreWithSpace implements PipeTransform {
  transform(value: string): string {
    if (isNilty(value)) return '';
    return value.replace(/_/g, ' ');
  }
}

@NgModule({
  imports: [],
  declarations: [
    MyDatePipe,
    MyDateTimePipe,
    MyCurrencyPipe,
    CapitalizePipe,
    IsNullOrUndefined,
    IsNullOrUndefinedOrEmptyString,
    BooleanIconPipe,
    MyPercentPipe,
    ReadPropertyPipe,
    FileNameFromUrl,
    replaceUnderscoreWithSpace,
  ],
  providers: [DecimalPipe, DatePipe],
  exports: [
    MyDatePipe,
    MyDateTimePipe,
    MyCurrencyPipe,
    CapitalizePipe,
    IsNullOrUndefined,
    IsNullOrUndefinedOrEmptyString,
    BooleanIconPipe,
    MyPercentPipe,
    ReadPropertyPipe,
    FileNameFromUrl,
    replaceUnderscoreWithSpace,
  ],
})
export class PipeModule {}
